import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getUserSubstateLabel, getUserAddressInfo} from './UserUtil';

async function generatePartyUserListToAoa({records, instance}) {
  return [
    [`${instance.title} 出席紀錄`, '', '', '', '', ''],
    [
      `會議日期：${instance.start_date} ${instance.start_time} - ${instance.end_time}`,
      '',
      '',
      '',
      '',
      '',
    ],
    [`會議地點：${instance.address}`, '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['編號', '姓名', '會員序號', '狀態', '簽到', '備註'],
    ...records[0].users.map((r, i) => {
      return [
        `${i + 1}`,
        `${r.name}`,
        `${r.LAWYER_NO}`,
        `${getUserSubstateLabel(r.substate)}`,
        `${r.is_attend ? '已報到' : ''}`,
        ``,
      ];
    }),
  ];
}

async function generatePartyMandatorListToAoa({records, instance}) {
  return [
    [`${instance.title} 委託出席紀錄`, '', '', '', '', ''],
    [
      `會議日期：${instance.start_date} ${instance.start_time} - ${instance.end_time}`,
      '',
      '',
      '',
      '',
      '',
    ],
    [`會議地點：${instance.address}`, '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['編號', '姓名', '會員序號', '狀態', '委託人簽到', '備註'],
    ...records[0].users
      .filter((r) => !!r.mandator)
      .map((r, i) => {
        const mandatorUser = instance.users.find(
          (user) => user.user_id === r.mandator,
        );

        return [
          `${i + 1}`,
          `${r.name}`,
          `${r.LAWYER_NO}`,
          `${getUserSubstateLabel(r.substate)}`,
          `${r.is_attend ? `${mandatorUser.name} 已報到` : ''}`,
          ``,
        ];
      }),
  ];
}

async function generatePartyExtraListToAoa({records, instance}) {
  return [
    [`${instance.title} 額外名單出席紀錄`, '', '', '', '', ''],
    [
      `會議日期：${instance.start_date} ${instance.start_time} - ${instance.end_time}`,
      '',
      '',
      '',
      '',
      '',
    ],
    [`會議地點：${instance.address}`, '', '', '', '', ''],
    ['', '', '', '', '', ''],
    ['編號', '姓名', '會員序號', '狀態', '簽到', '備註'],
    ...records[0].users
      .filter((r) => instance.extras.includes(r.user_id))
      .map((r, i) => {
        return [
          `${i + 1}`,
          `${r.name}`,
          `${r.LAWYER_NO}`,
          `${getUserSubstateLabel(r.substate)}`,
          `${r.is_attend ? '已報到' : ''}`,
          ``,
        ];
      }),
  ];
}

async function generatePartyLessFreeGiftListToAoa({records, instance}) {
  let giftLessDetailDisplay = ``;
  let giftLessUsers = records[0].users.filter(
    (r) => Array.isArray(r.less_free_gift) && r.less_free_gift.length > 0,
  );
  let usersInfo = null;
  let companiesInfo = null;

  for (let i = 0; i < instance.gifts.length; i++) {
    const giftLessLength = records[0].users
      .filter((user) => Array.isArray(user.less_free_gift))
      .filter((user) => user.less_free_gift.length > 0)
      .filter((user) => user.less_free_gift.includes(instance.gifts[i])).length;

    giftLessDetailDisplay += `${instance.gifts[i]}缺少：${giftLessLength}${
      i + 1 !== instance.gifts.length ? '、' : ''
    }`;
  }

  if (giftLessUsers.length > 0) {
    usersInfo = await JStorage.fetchAllDocuments(
      'user_profile',
      {
        owner: {
          $in: giftLessUsers.map((user) => user.user_id),
        },
      },
      null,
      {
        id: 1,
        owner: 1,
        company_current: 1,
        company_zip_code: 1,
        company_city: 1,
        company_detail: 1,
        company_phone: 1,
        mail_address_zip_code: 1,
        mail_address_city: 1,
        mail_address_detail: 1,
        residence_address_zip_code: 1,
        residence_address_city: 1,
        residence_address_detail: 1,
        INFORM_TO: 1,
      },
    );

    companiesInfo = await JStorage.fetchAllDocuments(
      'Company',
      {
        _id: {
          $in: usersInfo
            .filter((user) => !!user.company_current)
            .map((user) => ({$oid: user.company_current})),
        },
      },
      null,
      {
        CONAME: 1,
        address_zip_code: 1,
        address_city: 1,
        address_detail: 1,
        COPHONE1: 1,
      },
    );
  }

  return [
    [`${instance.title} 贈品缺少紀錄`, '', '', '', '', '', ''],
    [
      `會議日期：${instance.start_date} ${instance.start_time} - ${instance.end_time}`,
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    [`會議地點：${instance.address}`, '', '', '', '', '', ''],
    [`${giftLessDetailDisplay}`, '', '', '', '', '', ''],
    [
      '編號',
      '姓名',
      '會員序號',
      '狀態',
      '贈品缺少',
      '地址',
      '現在事務所電話',
      '備註',
    ],
    ...giftLessUsers.map((r, i) => {
      let giftArray = [];

      let targetUserInfo = usersInfo.find((u) => u.owner === r.user_id);
      let targetCompanyInfo = companiesInfo.find(
        (c) => c.id === targetUserInfo.company_current,
      );

      for (let i = 0; i < records[0].gifts.length; i++) {
        const idx = r.less_free_gift.findIndex(
          (g) => g === records[0].gifts[i],
        );

        if (idx > -1) {
          giftArray.push(r.less_free_gift[idx]);
        }
      }

      let giftDisplay = ``;

      for (let n = 0; n < giftArray.length; n++) {
        giftDisplay += `${giftArray[n]}${
          n + 1 !== giftArray.length ? '、' : ''
        }`;
      }

      return [
        `${i + 1}`,
        `${r.name}`,
        `${r.LAWYER_NO}`,
        `${getUserSubstateLabel(r.substate)}`,
        `${giftDisplay}`,
        `${getUserAddressInfo({
          user: targetUserInfo,
          company: targetCompanyInfo,
        })}`,
        `${targetUserInfo?.company_phone || targetCompanyInfo?.COPHONE1}`,
        `${r.note || ''}`,
      ];
    }),
  ];
}

function generatePartyWorkSheet(ws) {
  const merge = [
    // s = start, r = row, c = col, e = end
    {s: {r: 0, c: 0}, e: {r: 0, c: 5}},
    {s: {r: 1, c: 0}, e: {r: 1, c: 5}},
    {s: {r: 2, c: 0}, e: {r: 2, c: 5}},
    {s: {r: 3, c: 0}, e: {r: 3, c: 5}},
  ];
  ws['!merges'] = merge;

  //title
  const titles = [
    'A1',
    'A2',
    'A3',
    'A4',
    'B1',
    'B2',
    'B3',
    'B4',
    'C1',
    'C2',
    'C3',
    'C4',
    'D1',
    'D2',
    'D3',
    'D4',
    'E1',
    'E2',
    'E3',
    'E4',
    'F1',
    'F2',
    'F3',
    'F4',
  ];

  for (let i = 0; i < titles.length; i++) {
    const cell = titles[i];

    if (i === 0) {
      ws[cell].s = {
        sz: 22,
        bold: true,
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    } else {
      ws[cell].s = {
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    }
  }

  //header
  const headers = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5'];
  for (let i = 0; i < headers.length; i++) {
    const cell = headers[i];

    ws[cell].s = {
      bold: true,
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
      top: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      bottom: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      left: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      right: {
        style: 'thin',
        color: {rgb: '000000'},
      },
    };
  }

  //cell style
  const cells = Object.keys(ws);
  const excludeCells = ['!cols', '!merges', '!ref', ...titles, ...headers];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    if (!excludeCells.includes(cell)) {
      if (cell.indexOf('B') > -1) {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 18,
          bold: true,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      } else {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 12,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      }
    }
  }

  //col width
  ws['!cols'] = [
    {wpx: 70},
    {wpx: 151},
    {wpx: 100},
    {wpx: 120},
    {wpx: 180},
    {wpx: 152},
  ];

  //row height
  ws['!rows'] = [{hpx: 35}, {hpx: 30}, {hpx: 30}, {hpx: 30}];
  const serialNumbers = cells
    .filter((c) => c.indexOf('A') > -1)
    .filter((c) => !['A1', 'A2', 'A3'].includes(c));

  for (let i = 0; i < serialNumbers.length; i++) {
    ws['!rows'].push({hpx: 48});
  }
}

function generatePartyLessFreeGiftListWorkSheet(ws) {
  const merge = [
    // s = start, r = row, c = col, e = end
    {s: {r: 0, c: 0}, e: {r: 0, c: 7}},
    {s: {r: 1, c: 0}, e: {r: 1, c: 7}},
    {s: {r: 2, c: 0}, e: {r: 2, c: 7}},
    {s: {r: 3, c: 0}, e: {r: 3, c: 7}},
  ];
  ws['!merges'] = merge;

  //title
  const titles = [
    'A1',
    'A2',
    'A3',
    'A4',
    'B1',
    'B2',
    'B3',
    'B4',
    'C1',
    'C2',
    'C3',
    'C4',
    'D1',
    'D2',
    'D3',
    'D4',
    'E1',
    'E2',
    'E3',
    'E4',
    'F1',
    'F2',
    'F3',
    'F4',
  ];

  for (let i = 0; i < titles.length; i++) {
    const cell = titles[i];

    if (i === 0) {
      ws[cell].s = {
        sz: 22,
        bold: true,
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    } else {
      ws[cell].s = {
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };
    }
  }

  //header
  const headers = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5'];
  for (let i = 0; i < headers.length; i++) {
    const cell = headers[i];

    ws[cell].s = {
      bold: true,
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
      top: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      bottom: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      left: {
        style: 'thin',
        color: {rgb: '000000'},
      },
      right: {
        style: 'thin',
        color: {rgb: '000000'},
      },
    };
  }

  //cell style
  const cells = Object.keys(ws);
  const excludeCells = ['!cols', '!merges', '!ref', ...titles, ...headers];

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    if (!excludeCells.includes(cell)) {
      if (cell.indexOf('B') > -1) {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 18,
          bold: true,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      } else if (cell.indexOf('F') > -1) {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 12,
          alignment: {
            vertical: 'center',
            wrapText: true,
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      } else {
        ws[cell].s = {
          ...ws[cell].s,
          sz: 12,
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          top: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          bottom: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          left: {
            style: 'thin',
            color: {rgb: '000000'},
          },
          right: {
            style: 'thin',
            color: {rgb: '000000'},
          },
        };
      }
    }
  }

  //col width
  ws['!cols'] = [
    {wpx: 70},
    {wpx: 151},
    {wpx: 100},
    {wpx: 120},
    {wpx: 180},
    {wpx: 250},
    {wpx: 152},
    {wpx: 152},
  ];

  //row height
  ws['!rows'] = [{hpx: 35}, {hpx: 30}, {hpx: 30}, {hpx: 30}, {hpx: 30}];

  const serialNumbers = cells
    .filter((c) => c.indexOf('A') > -1)
    .filter((c) => !['A1', 'A2', 'A3'].includes(c));

  for (let i = 0; i < serialNumbers.length; i++) {
    ws['!rows'].push({hpx: 22 * 4});
  }
}

export {
  generatePartyUserListToAoa,
  generatePartyMandatorListToAoa,
  generatePartyExtraListToAoa,
  generatePartyLessFreeGiftListToAoa,
  generatePartyWorkSheet,
  generatePartyLessFreeGiftListWorkSheet,
};
